/**
 * validarTipo
 *
 * @param {*} variavel
 * @param {string} tipo ['object', 'string', 'number', 'undefined', 'boolean', 'function', 'xml']
 * @returns
 */
const validarTipo = (variavel, tipo) => {
  if (!tipo || typeof tipo !== 'string') {
    return false;
  }
  const tipoVar = typeof variavel;

  if (variavel === null || variavel === undefined || tipoVar !== tipo) {
    return false;
  }
  return true;
};

export default validarTipo;
