import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Col, Tabs, Tab, Row } from 'react-bootstrap';

import { Funci } from '../../services';
import { Carregando, Cabecalho, Menu, GraficoPizza, GraficoColuna } from '../../components';
import { alertar } from '../../utils';
import { modelResumo } from '../../models';

export default props => {
  const location = useLocation();
  const [carregando, setCarregando] = useState(false);
  const [intencoes, setIntencoes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);

  useEffect(() => {
    const listaPosicoes = [
      { codigo: 'F', label: 'Favorável', color: '#8bbc21' },
      { codigo: 'N', label: 'Neutro', color: '#f28f43' },
      { codigo: 'C', label: 'Contrário', color: '#c42525' },
      { codigo: 'X', label: 'Não definido', color: 'gray' },
    ];
    const somarIntencoes = array => {
      const result = [];
      array.reduce((res, value) => {
        if (!res[value.posicaoLabel]) {
          res[value.posicaoLabel] = { name: value.posicaoLabel, y: 0, color: listaPosicoes.filter(l => l.codigo === value.posicao)[0].color };
          result.push(res[value.posicaoLabel]);
        }
        res[value.posicaoLabel].y += parseInt(value.qtde, 10);
        return res;
      }, {});
      return result;
    };

    const somarCategorias = array => {
      // Obtendo objeto padrão com categorias únicas
      const categs = array.map(a => a.categoria).filter((value, index, self) => self.indexOf(value) === index);
      const result = listaPosicoes.map(i => ({ name: i.label, data: [], color: i.color }));

      result.forEach(c => {
        categs.forEach(l => {
          const itens = array.filter(a => a.posicaoLabel === c.name && a.categoria === l);
          if (itens.length > 0) c.data.push(itens[0].qtde);
        });
      });

      return result;
    };
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.consultarResumoCategoria();
        if (consulta.erro) {
          setIntencoes([]);
          setCategorias([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setIntencoes([]);
            setCategorias([]);
            alertar('warning', 'Resumo não encontrado.');
          }
          if (consulta.length > 0) {
            const resumo = consulta.map(c => modelResumo(c));
            const somaIntencoes = somarIntencoes(resumo);
            const somaCategorias = somarCategorias(resumo);
            setListaCategorias(resumo.map(a => a.categoria).filter((value, index, self) => self.indexOf(value) === index));
            setIntencoes(somaIntencoes);
            setCategorias(somaCategorias);
          }
        }
      } catch (e) {
        setIntencoes([]);
        setCategorias([]);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, []);

  return (
    <>
      <Cabecalho />
      <Row className="w-100 flex-grow-1">
        <Menu location={location} />
        <Col as="div" className="rolagem">
          <Container fluid>
            <Carregando show={carregando} />
            <br />
            <Tabs defaultActiveKey="relatorios" id="uncontrolled-tab-example">
              <Tab mountOnEnter unmountOnExit={false} variant="pills" eventKey="relatorios" title="Relatórios">
                {intencoes && intencoes.length > 0 && (
                  <GraficoPizza lista={intencoes} titulo="Resumo geral de intenções" categoria="Posicionamento" />
                )}
                {categorias && categorias.length > 0 && (
                  <GraficoColuna lista={categorias} titulo="Resumo por categoria" categorias={listaCategorias} />
                )}
              </Tab>
            </Tabs>
          </Container>
        </Col>
      </Row>
    </>
  );
};
