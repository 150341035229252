import React from 'react';

const ThWithOrder = props => {
  const { children, campo = '', campoAtual = null, ordemAtual = null, ordenar = () => {}, className = '' } = props;
  const ativo = campo === campoAtual;

  return (
    <th className={`${className}`}>
      {children}
      <i
        className={`ml-1 fa fa-caret-up ${ativo && ordemAtual === 'ASC' ? 'text-success' : 'text-secondary'}`}
        onClick={() => ordenar(campo, 'ASC')}
      />
      <i
        className={`ml-1 fa fa-caret-down ${ativo && ordemAtual === 'DESC' ? 'text-success' : 'text-secondary'}`}
        onClick={() => ordenar(campo, 'DESC')}
      />
    </th>
  );
};

export default ThWithOrder;
