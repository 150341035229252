import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HomePage } from './containers/home';
import { ListaSocios, SocioDetalhe } from './containers/gerenciamento';
import { Relatorios } from './containers/relatorios';
import NoMatch from './components/NoMatch';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrivateRoute, Carregando } from './components';
import Login from './containers/auth/Login';
import { firebaseConfig } from './constants';
import { Auth, Funci, Requisicoes } from './services';
import { Logout } from './containers/auth';

firebase.initializeApp(firebaseConfig);

// Configurações do toastify
toast.configure();

const App = () => {
  const [carregando, setCarregando] = useState(true);
  const [authed, setAuthed] = useState(false);

  // Instância da classe Auth com os métodos para controle do tempo da sessão

  // Controle de status do login
  useEffect(() => {
    const inicializar = async () => {
      await Auth.onAuth(async usuario => {
        const auth = new Auth();
        const funci = new Funci();
        if (usuario) {
          auth.setTimer();
          setAuthed(true);
        } else {
          funci.limparPerfisUsuario();
          auth.clearTimer();
          setAuthed(false);
        }
        setCarregando(false);
      });
    };
    inicializar();
  }, []);

  // Configuração das requisições com base no status de login
  const req = new Requisicoes();
  const { apiKey, appId } = Auth.getApiInfo();
  const { uid } = Auth.getUsuario() || { uid: null };
  Auth.getIdToken().then((token = null) => {
    req.configureAxios(apiKey, appId, token, uid);
  });

  // Atualiza o prazo da sessão conforme uso da aplicação
  document.getElementById('root').addEventListener('click', Auth.refreshTimer);

  if (carregando) {
    return <Carregando />;
  }
  return (
    <BrowserRouter>
      <ToastContainer newestOnTop style={{ width: '500px', marginTop: 50 }} />
      <div className="d-flex flex-column w-100 m-0" style={{ height: '100vh', width: '100vw' }}>
        <Switch>
          {/* Auth */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          {/* <Route exact path="/esqueci" component={Esqueci} /> */}

          {/* Home */}
          <PrivateRoute exact authed={authed} path="/" component={HomePage} />

          {/* Gerenciamento */}
          <PrivateRoute exact authed={authed} path="/gerenciamento" component={ListaSocios} />
          <PrivateRoute authed={authed} path="/socio/:id" component={SocioDetalhe} />

          {/* Relatórios */}
          <PrivateRoute exact authed={authed} path="/relatorios" component={Relatorios} />

          {/* Misc */}
          <Route path="*" component={NoMatch} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
