import { validarTipo } from '../utils';
import Auth from './Auth';
import Requisicoes from './Requisicoes';

/**
 * Funci
 * Classe singleton
 *
 * @export
 * @class Funci
 */
export default class Funci {
  /**
   * Cria uma instância singleton da classe Funci.
   * @memberof Funci
   */
  constructor() {
    if (!Funci.instance) {
      this.autenticado = false;
      this.perfis = [];
      this.perfisCarregados = false;
      Funci.instance = this;
    }
    return Funci.instance;
  }

  /**
   * isAutenticado
   *
   * @returns
   * @memberof Funci
   */
  isAutenticado() {
    return this.autenticado;
  }

  /**
   * listarSocios
   *
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} criterio
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async listarSocios(pagina, qtdePorPagina, criterio, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const socios = await Requisicoes.getbuscarSocios(pagina, qtdePorPagina, criterio, campoOrdenacao, sentidoOrdenacao);
    return socios;
  }

  /**
   * atualizarPosicao
   *
   * @param {number} id
   * @param {string} posicao
   * @memberof Funci
   */
  static async atualizarPosicao(id, posicao) {
    if (!validarTipo(id, 'number')) return false;
    if (!validarTipo(posicao, 'string')) return false;
    const atualizacao = await Requisicoes.patchSocioAtualizar({ id, posicao });
    return atualizacao;
  }

  /**
   * consultarSocioPorId
   *
   * @param {number} id
   * @memberof Funci
   */
  static async consultarSocioPorId(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const socio = await Requisicoes.getSocioConsultar(id);
    return socio;
  }

  /**
   * consultarResumoCategoria
   *
   * @memberof Funci
   */
  static async consultarResumoCategoria() {
    const consulta = await Requisicoes.getConsultarResumoCategoria();
    return consulta;
  }

  /**
   * isFunci
   *
   * @static
   * @param {string} email
   * @memberof Funci
   */
  static async isFunci(email) {
    if (!validarTipo(email, 'string')) return false;
    const convites = await Requisicoes.getIsFunci(email);
    return convites;
  }

  /** *********************************************************************************************************************************************
   *  ANTIGOS (CONFIRMAR UTILIZAÇÂO ANTES DE APAGAR)
   ********************************************************************************************************************************************* */

  /**
   * consultarSocioPorTitulo
   *
   * @param {*} titulo
   * @returns
   * @memberof Funci
   */
  static async consultarSocioPorTitulo(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const socio = await Requisicoes.getSocioPorTitulo(titulo);
    return socio;
  }

  /**
   * convitesDispAvulsos
   *
   * @param {*} titulo
   * @memberof Funci
   */
  static async convitesDispAvulsos(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const convites = await Requisicoes.getConvitesAvulsosDispPorTitulo(titulo);
    return convites;
  }

  /**
   * convitesDispChurras
   *
   * @param {string} ano
   * @param {string} titulo
   * @memberof Funci
   */
  static async convitesDispChurras(ano, titulo) {
    if (!validarTipo(ano, 'string')) return false;
    if (!validarTipo(titulo, 'string')) return false;
    const convites = await Requisicoes.getConvitesChurrasDispPorTitulo(ano, titulo);
    return convites;
  }

  /**
   * convitesDispChurrasPorReserva
   *
   * @param {string} idReserva
   * @param {string} ano
   * @memberof Funci
   */
  static async convitesDispChurrasPorReserva(idReserva, ano) {
    if (!validarTipo(idReserva, 'string')) return false;
    if (!validarTipo(ano, 'string')) return false;
    const convites = await Requisicoes.getConvitesChurrasDispPorReserva(idReserva, ano);
    return convites;
  }

  /**
   * convitesDispChurrasqueiras
   *
   * @param {string} ano
   * @memberof Funci
   */
  async convitesDispChurrasqueiras(ano) {
    if (!this.uid) return false;
    const convites = await Requisicoes.getConvitesDispChurrasq(this.uid, ano);
    return convites;
  }

  /**
   * emitirConvite
   *
   * @param {object} convite
   * @memberof Funci
   */
  static async emitirConvite(convite) {
    if (!convite) return false;
    const convites = await Requisicoes.postEmitirConvite(convite);
    return convites;
  }

  /**
   * consultarConvite
   *
   * @param {*} idConvite
   * @memberof Funci
   */
  static async consultarConvite(idConvite) {
    if (!validarTipo(idConvite, 'string') && !validarTipo(idConvite, 'number')) return false;
    const reservas = await Requisicoes.getConsultarConvite(idConvite);
    return reservas;
  }

  /**
   * confirmarEntrada
   *
   * @param {*} idConvite
   * @memberof Funci
   */
  static async confirmarEntrada(idConvite) {
    if (!validarTipo(idConvite, 'string') && !validarTipo(idConvite, 'number')) return false;
    const reservas = await Requisicoes.postConfirmarEntrada({ idConvite });
    return reservas;
  }

  /**
   * cancelarConvite
   *
   * @param {*} convite
   * @memberof Funci
   */
  static async cancelarConvite(convite) {
    if (!validarTipo(convite, 'object')) return false;
    const reservas = await Requisicoes.patchAlterarConvite({ ...convite, acao: 'C' });
    return reservas;
  }

  /**
   * infoTurnos
   *
   * @memberof Funci
   */
  static async infoTurnos() {
    const convites = await Requisicoes.getTurnos();
    return convites;
  }

  /**
   * listarChurrasqueiras
   *
   * @memberof Funci
   */
  static async listarChurrasqueiras() {
    const convites = await Requisicoes.getChurrasqueiras();
    return convites;
  }

  /**
   * consultarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async consultarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.getChurrasqueiraPorId(idChurrasqueira);
    return convites;
  }

  /**
   * ativarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async ativarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ idChurrasqueira, acao: 'A' });
    return convites;
  }

  /**
   * desativarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async desativarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ idChurrasqueira, acao: 'D' });
    return convites;
  }

  /**
   * editarChurrasqueira
   *
   * @param {*} churrasqueira
   * @memberof Funci
   */
  static async editarChurrasqueira(churrasqueira) {
    if (!validarTipo(churrasqueira, 'object')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ ...churrasqueira, acao: 'E' });
    return convites;
  }

  /**
   * listarReservas
   *
   * @param {string} dataInicial - formato YYYY-MM-DD
   * @param {string} dataFinal - formato YYYY-MM-DD
   * @param {string} titulo
   * @memberof Funci
   */
  static async listarReservas(dataInicial, dataFinal, titulo) {
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(titulo, 'string')) return false;
    const reservas = await Requisicoes.getListarReservas(dataInicial.replace(/\//g, '-'), dataFinal.replace(/\//g, '-'), titulo);
    return reservas;
  }

  /**
   * consultarReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async consultarReserva(idReserva) {
    if (!validarTipo(idReserva, 'string')) return false;
    const reserva = await Requisicoes.getConsultarReservaPorId(idReserva, this.uid);
    return reserva;
  }

  /**
   * cancelarReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async cancelarReserva(idReserva) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    const alteracao = await Requisicoes.patchAlterarReserva({ idReserva, acao: 'C' });
    return alteracao;
  }

  /**
   * alterarChurrasReserva
   *
   * @param {*} idReserva
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async alterarChurrasReserva(idReserva, idChurrasqueira) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const alteracao = await Requisicoes.patchAlterarReserva({ idReserva, tipoObjeto: 'C', idObjeto: idChurrasqueira, acao: 'A' });
    return alteracao;
  }

  /**
   * listarChurrasqueirasDispPorData
   *
   * @param {*} data - Formato YYYY-MM-DD
   * @memberof Funci
   */
  static async listarChurrasqueirasDispPorData(data) {
    if (!validarTipo(data, 'string')) return false;
    const reserva = await Requisicoes.getChurrasqDispPorData(data);
    return reserva;
  }

  /**
   * listarReservasDatasDisp
   *
   * @memberof Funci
   */
  static async listarReservasDatasDisp() {
    const reserva = await Requisicoes.getReservasDatasDisp();
    return reserva;
  }

  /**
   * listarConvitesPorIdReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async listarConvitesPorIdReserva(idReserva) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    const consulta = await Requisicoes.getConvitesPorIdReserva(idReserva);
    return consulta;
  }

  /**
   * enviarConvitePorEmail
   *
   * @param {object} convite
   * @memberof Funci
   */
  static async enviarConvitePorEmail(convite) {
    if (!validarTipo(convite, 'object')) return false;
    const envio = await Requisicoes.postConviteEnviarEmail(convite);
    return envio;
  }

  /**
   * emitirReserva
   *
   * @param {object} reserva
   * @memberof Funci
   */
  static async emitirReserva(reserva) {
    if (!validarTipo(reserva, 'object')) return false;
    const emissao = await Requisicoes.postEmitirReserva(reserva);
    return emissao;
  }

  /**
   * listarUsuariosSistema
   *
   * @param {string} titulo
   * @memberof Funci
   */
  static async listarUsuariosSistema(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const resposta = await Requisicoes.getListarUsuariosSistema(titulo);
    return resposta;
  }

  /**
   * consultarUsuarioSistema
   *
   * @param {string} uid
   * @memberof Funci
   */
  static async consultarUsuarioSistema(uid) {
    if (!validarTipo(uid, 'string')) return false;
    const resposta = await Requisicoes.getConsultarUsuarioSistema(uid);
    return resposta;
  }

  /**
   * atualizarPerfisUsuario
   *
   * @memberof Funci
   */
  async atualizarPerfisUsuario() {
    const { uid } = Auth.getUsuario() || { uid: null };
    const resposta = await Requisicoes.getListarPerfisUsuario(uid);
    if (resposta && validarTipo(resposta, 'object')) {
      this.perfis = resposta;
      this.perfisCarregados = true;
      return true;
    }
    return false;
  }

  /**
   * limparPerfisUsuario
   *
   * @memberof Funci
   */
  async limparPerfisUsuario() {
    this.perfis = [];
    this.perfisCarregados = false;
  }

  /**
   * hasPerfil
   *
   * Busca se o usuário possui um determinado perfil, ou se possui algum dos perfis de uma lista informada
   *
   * @param {string | array} perfil
   * @memberof Funci
   */
  hasPerfil(perfil) {
    if (validarTipo(perfil, 'string')) {
      const filtro = this.perfis.filter(p => p.descricao === perfil);
      return filtro.length > 0;
    }
    if (validarTipo(perfil, 'object')) {
      const filtro = this.perfis.filter(p => perfil.findIndex(i => i === p.descricao) >= 0);
      return filtro.length > 0;
    }
  }

  /**
   * listarPerfisSistema
   *
   * @memberof Funci
   */
  static async listarPerfisSistema() {
    const resposta = await Requisicoes.getListarPerfisSistema();
    return resposta;
  }

  /**
   * desativarUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async desativarUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'D' });
    return resposta;
  }

  /**
   * ativarUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async ativarUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'A' });
    return resposta;
  }

  /**
   * atualizarPemissoesUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async atualizarPemissoesUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'AP' });
    return resposta;
  }

  /**
   * listarAcessosFisicos
   *
   * @param {string} dataInicial - formato YYYY-MM-DD
   * @param {string} dataFinal - formato YYYY-MM-DD
   * @param {string} tipoIdentificador
   * @param {string} identificador
   * @memberof Funci
   */
  static async listarAcessosFisicos(dataInicial, dataFinal, tipoIdentificador, identificador) {
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(tipoIdentificador, 'string')) return false;
    if (!validarTipo(identificador, 'string')) return false;
    const reservas = await Requisicoes.getListarAcessosFisicos(
      dataInicial.replace(/\//g, '-'),
      dataFinal.replace(/\//g, '-'),
      tipoIdentificador,
      identificador,
    );
    return reservas;
  }
}
