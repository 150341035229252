import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Carregando } from '../../components';
import { Auth } from '../../services';

export default withRouter(props => {
  const { history } = props;

  useEffect(() => {
    const inicializar = async () => {
      await Auth.sair();
      history.replace('/');
    };
    inicializar();
  }, [history]);

  return <Carregando />;
});
