const parseMeioComunica = (lista, tipo) => {
  if (lista.length > 0) {
    const emails = lista.filter(i => i.tipo === tipo);
    if (emails.length > 0) {
      return emails.map(i => i.valor).join(' / ');
    }
  }
  return '';
};

export default parseMeioComunica;
