import React from 'react';
import { Col, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default () => {
  return (
    <Col className="bg-dark" xs="auto">
      <Nav className="flex-column">
        <Nav.Item>
          <LinkContainer to="/">
            <Nav.Link className="text-white border-bottom ml-2">Home</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/gerenciamento">
            <Nav.Link className="text-white border-bottom ml-2">Gerenciamento</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/relatorios">
            <Nav.Link className="text-white border-bottom ml-2">Relatórios</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/logout">
            <Nav.Link className="text-white border-bottom ml-2">Sair</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </Nav>
    </Col>
  );
};
