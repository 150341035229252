/**
 * @method modelLocalAcesso
 * @description Modelo padrão de objeto Resumo de intenções
 * @param {string} categoria - categoria do sócio
 * @param {string} posicao - posicionamento de intençao de voto
 * @param {number} qtde - Qtde de socios na respectivaposição.
 * @returns {object} Objeto de formatado
 */
export default ({ categoria, posicao, qtde }) => {
  const categoriasLabels = {
    F: 'Favorável',
    N: 'Neutro',
    C: 'Contrário',
    X: 'Não definido',
  };
  const posicaoLabel = categoriasLabels[posicao];
  return {
    categoria: `${categoria}`,
    posicao: `${posicao}`,
    posicaoLabel,
    qtde: parseInt(qtde, 10),
  };
};
