import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

export default props => {
  return (
    <Nav variant="pills" className="navbar navbar-dark bg-dark border-bottom border-white w-100 m-0 justify-content-between">
      <Col xs="auto">
        <h3 className="text-white">Gerenciador de Intenções</h3>
      </Col>
      <Col>
        <Row className="justify-content-end">
          <Col>
            <div className="text-danger text-right">
              {/* Local reservado para broadcasts no cabeçalho */}
              {/* Não utilize o botão de voltar do navegador */}
              <br />
              <span className="text-white" style={{ fontSize: 10 }}>
                {`Versão ${process.env.REACT_APP_VERSION}`}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Nav>
  );
};
