import React from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import { Card } from 'react-bootstrap';

/**
 * GraficoPizza
 *
 * @param {array} lista
 * @param {string} titulo
 * @param {string} categoria
 * @returns
 */
const GraficoPizza = props => {
  const { lista = [], titulo = '', categoria = '' } = props;

  HC_exporting(Highcharts);

  const options = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
    },
    chart: {
      type: 'pie',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    title: {
      text: titulo,
    },
    series: [
      {
        name: categoria,
        data: lista,
      },
    ],
  };

  return (
    <Card>
      <Card.Body>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Card.Body>
    </Card>
  );
};

export default GraficoPizza;
