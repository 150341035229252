const ambiente = process.env.NODE_ENV;

const firebaseConfig =
  ambiente === 'development'
    ? {
        apiKey: 'AIzaSyCkwEbcK74HF5wjpvynZJIr9KtpKnuPDJ4',
        authDomain: 'intencoes-6ad5f.firebaseapp.com',
        projectId: 'intencoes-6ad5f',
        storageBucket: 'intencoes-6ad5f.appspot.com',
        messagingSenderId: '42992512713',
        appId: '1:42992512713:web:64a5fea0b1f8666255e718',
        measurementId: 'G-7WVJZ72MKS',
      }
    : {
        apiKey: 'AIzaSyCkwEbcK74HF5wjpvynZJIr9KtpKnuPDJ4',
        authDomain: 'intencoes-6ad5f.firebaseapp.com',
        projectId: 'intencoes-6ad5f',
        storageBucket: 'intencoes-6ad5f.appspot.com',
        messagingSenderId: '42992512713',
        appId: '1:42992512713:web:64a5fea0b1f8666255e718',
        measurementId: 'G-7WVJZ72MKS',
      };

export default firebaseConfig;
