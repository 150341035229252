import env from './env';

const build = endpoint => `${env.URL_API}${endpoint}`;

const api = {
  INFO: { url: () => build('/info'), metodo: 'GET' },
  FOTO: { url: id => build(`/v1/foto/${id}`), metodo: 'GET' },
  SOCIOS_LISTAR: {
    url: (pagina, qtdePorPagina, criterio, campoOrdenacao, sentidoOrdenacao) =>
      build(`/v1/socios/${pagina}/${qtdePorPagina}/${criterio}/${campoOrdenacao}/${sentidoOrdenacao}`),
    metodo: 'GET',
  },
  SOCIO_CONSULTAR: { url: id => build(`/v1/socio/${id}`), metodo: 'GET' },
  SOCIO_ATUALIZAR: { url: () => build('/v1/socio'), metodo: 'PATCH' },
  HISTORICO_CONSULTAR: { url: id => build(`/v1/historico/${id}`), metodo: 'GET' },
  RESUMO_CATEGORIAS: { url: () => build('/v1/categorias/resumo'), metodo: 'GET' },
};

export default api;
