import axios from 'axios';

import { isDomesticServer, validarTipo } from '../utils';
import { api } from '../constants';
import { Auth } from '.';

export default class Requisicoes {
  /**
   * Cria uma instância singleton da classe Requisicoes.
   * Esta implementação é necessária para a utilização correta dos tokens no momento exato de sua alteração.
   * @memberof Requisicoes
   */
  constructor() {
    if (!Requisicoes.instance) {
      Requisicoes.instance = this;
    }

    return Requisicoes.instance;
  }
  /**
   * configureAxios
   *
   * @static
   * @param {string} apiKey
   * @param {string} appId
   * @param {string} token
   * @param {string} uid
   * @param {string} instanceId
   * @memberof Requisicoes
   */
  configureAxios(apiKey, appId, token, uid, instanceId = 'PAINEL-ADMIN') {
    return new Promise((resolve, reject) => {
      try {
        // remove qualquer interceptor que existe previamente
        if (this.intercept !== undefined) {
          axios.interceptors.request.eject(this.intercept);
        }
        // cria um novo interceptor e registra seu id no atributo intercept
        this.intercept = axios.interceptors.request.use(
          req => {
            // Não entrega o token caso o servidor não seja doméstico
            if (isDomesticServer(req.url)) {
              req.headers.token = `${apiKey}`;
              req.headers.id = `${appId}`;
              req.headers['x-access-token'] = `${token || ''}`;
              req.headers.uid = `${uid || ''}`;
              req.headers.instanceId = `${instanceId || ''}`;
            }
            return req;
          },
          error => {
            throw error;
          },
        );
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * releaseAxios
   *
   * @memberof Requisicoes
   */
  releaseAxios() {
    if (this.intercept !== undefined) {
      axios.interceptors.request.eject(this.intercept);
    }
  }

  /**
   * requisitar
   *
   * @static
   * @param {string} type    - verbo da requisição [GET, POST, PUT...]
   * @param {string} URI     - URI completo do endpoint
   * @param {object} body    - corpo da requisição
   * @memberof Requisicoes
   */
  static async requisitar(type, URI, body = {}) {
    try {
      if (!validarTipo(type, 'string') || !validarTipo(URI, 'string') || !validarTipo(body, 'object')) return false;
      const raw = await axios({
        method: type.toLowerCase(),
        url: URI,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: type.toLowerCase() === 'get' ? null : body,
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 302 || status === 304 || status === 401 || status === 403 || status === 409,
      });
      if (raw.data.auth === false) {
        Auth.sair();
      }
      return raw.data;
    } catch (error) {
      console.warn('Erro na requisição:', error);
      const erro = { httpStatusCode: 500, data: `Não foi possível estabelecer conexão com o servidor. ${error}` };
      return erro;
    }
  }

  /**
   * tratarResposta
   *
   * @static
   * @param {*} response
   * @param {*} url
   * @memberof Requisicoes
   */
  static tratarResposta(response, url) {
    try {
      if (!response) return { erro: 'Erro ao receber resposta do servidor.' };
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200) return data;
      if (httpStatusCode === 401 || httpStatusCode === 403 || httpStatusCode === 409)
        return { erro: 'Usuário não autorizado. Solicite permissões e tente novamente.' };
      if (httpStatusCode === 412 || httpStatusCode === 428) return { erro: `${data}` };
      if (httpStatusCode >= 500) return { erro: `${data}. URL: ${url}` };
      return { erro: `Erro desconhecido ao se comunicar com o servidor. URL: ${url}`, data };
    } catch (error) {
      return { erro: `Erro de comunicação. ${error.message ? error.message.toString().substring(0, 50).concat('... ') : ''}URL: ${url}` };
    }
  }

  /**
   * getInfo
   *
   * @static
   * @memberof Requisicoes
   */
  static async getInfo() {
    const servico = api.INFO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getFoto
   *
   * @static
   * @param {string} id
   * @memberof Requisicoes
   */
  static async getFoto(id) {
    const servico = api.FOTO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getbuscarSocios
   *
   * @static
   * @param {string} pagina
   * @param {string} qtdePorPagina
   * @param {string} criterio
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getbuscarSocios(pagina, qtdePorPagina, criterio, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.SOCIOS_LISTAR;
    const url = servico.url(pagina, qtdePorPagina, criterio, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getSocioConsultar
   *
   * @static
   * @param {string} id
   * @memberof Requisicoes
   */
  static async getSocioConsultar(id) {
    const servico = api.SOCIO_CONSULTAR;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getHistoricoConsultar
   *
   * @static
   * @param {string} id
   * @memberof Requisicoes
   */
  static async getHistoricoConsultar(id) {
    const servico = api.HISTORICO_CONSULTAR;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchSocioAtualizar
   *
   * @static
   * @param {string} body
   * @memberof Requisicoes
   */
  static async patchSocioAtualizar(body) {
    const servico = api.SOCIO_ATUALIZAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarResumoCategoria
   *
   * @static
   * @memberof Requisicoes
   */
  static async getConsultarResumoCategoria() {
    const servico = api.RESUMO_CATEGORIAS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }
}
