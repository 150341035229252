import React from 'react';
import { Pagination } from 'react-bootstrap';

const Paginacao = props => {
  const { records, qtdePorPagina, paginaAtual, paginar = () => {} } = props;
  const pagIni = 1;
  const pagFim = Math.ceil(records / qtdePorPagina) || 1; // Garante que retorne pelo menos uma página
  const items = [];
  for (let number = pagIni; number <= pagFim; number++) {
    items.push(
      <Pagination.Item key={number} active={number === paginaAtual} onClick={() => paginar(number)}>
        {number}
      </Pagination.Item>,
    );
  }
  if (pagFim <= 5) {
    return (
      <Pagination>
        <Pagination.First onClick={() => paginar(pagIni)} />
        {items}
        <Pagination.Last onClick={() => paginar(pagFim)} />
      </Pagination>
    );
  }
  return (
    <Pagination>
      <Pagination.First onClick={() => paginar(pagIni)} />
      <Pagination.Prev onClick={() => paginar(paginaAtual - 1)} />
      {paginaAtual >= pagIni + 2 ? <Pagination.Item onClick={() => paginar(paginaAtual - 2)}>{paginaAtual - 2}</Pagination.Item> : null}
      {paginaAtual >= pagIni + 1 ? <Pagination.Item onClick={() => paginar(paginaAtual - 1)}>{paginaAtual - 1}</Pagination.Item> : null}
      <Pagination.Item active={true}>{paginaAtual}</Pagination.Item>
      {paginaAtual <= pagFim - 1 ? <Pagination.Item onClick={() => paginar(paginaAtual + 1)}>{paginaAtual + 1}</Pagination.Item> : null}
      {paginaAtual <= pagFim - 2 ? <Pagination.Item onClick={() => paginar(paginaAtual + 2)}>{paginaAtual + 2}</Pagination.Item> : null}
      <Pagination.Next onClick={() => paginar(paginaAtual + 1)} />
      <Pagination.Last onClick={() => paginar(pagFim)} />
    </Pagination>
  );
};

export default Paginacao;
