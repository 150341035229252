import React, { useContext } from 'react';
import { Accordion, Col, Row, useAccordionToggle, Card } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';

const AccordionLineToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle as={Card.Header} eventKey={eventKey} onClick={decoratedOnClick}>
      <Row>
        <Col>{children}</Col>
        <Col xs="auto">
          <i className={`fa fa-chevron-${isCurrentEventKey ? 'up' : 'down'} text-secondary`} />
        </Col>
      </Row>
    </Accordion.Toggle>
  );
};

export default AccordionLineToggle;
