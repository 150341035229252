import React from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import { Card } from 'react-bootstrap';

/**
 * GraficoColuna
 *
 * @param {array} lista
 * @param {string} titulo
 * @param {array} categorias
 * @returns
 */
const GraficoColuna = props => {
  const { lista = [], titulo = '', categorias = [''] } = props;

  HC_exporting(Highcharts);

  const options = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
    },
    chart: {
      type: 'column',
    },
    xAxis: {
      categories: categorias,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Quantidade por intenção',
      },
    },
    tooltip: {
      valueSuffix: ' sócios',
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true,
    },
    title: {
      text: titulo,
    },
    series: lista,
  };

  return (
    <Card>
      <Card.Body>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Card.Body>
    </Card>
  );
};

export default GraficoColuna;
