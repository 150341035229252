import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';

/**
 * Voltar
 *
 * @param {string} title [opcional] título do botão (default seta esquerda)
 * @param {string} to [opcional] rota para onde deseja ir. será feito push no histórico.
 */
const Voltar = ({ history, title, to = null }) => (
  <Col xs="auto">
    <Button
      variant={`outline-secondary ${title ? '' : 'border-0'}`}
      onClick={() => (to ? history.push(to) : history.goBack())}
      alt="Go back"
      style={title ? {} : { transform: 'rotate(180deg)', fontSize: '' }}
    >
      {title ? title : <h3 className="my-0">{String.fromCharCode(10132)}</h3>}
    </Button>
  </Col>
);

export default withRouter(Voltar);
