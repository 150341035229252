import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Col, Tabs, Tab, Form, Table, Badge, Row, Card } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';

import { Funci } from '../../services';
import { Carregando, ThWithOrder, Paginacao, Cabecalho, Menu, Posicao } from '../../components';
import { alertar, parseMeioComunica, useQuery } from '../../utils';
import semFoto from '../../assets/imgs/semfoto.jpg';

export default props => {
  const { history } = props;
  const componentRef = useRef();
  const query = useQuery();
  const location = useLocation();
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const { handleSubmit, register, errors } = useForm();
  const [socios, setSocios] = useState([]);

  // Itens de query da requisição
  const criterio = query.get('criterio') || '0';
  const pagina = parseInt(query.get('pagina'), 10) || 1;
  const qtdePagina = parseInt(query.get('qtdePagina'), 8) || 8;
  const campoOrdenacao = query.get('campoOrdenacao') || '0';
  const sentidoOrdenacao = query.get('sentidoOrdenacao') || '0';

  const navegar = (crit, pag, qtde, campo, sentido) => {
    setCarregando(true);
    try {
      let url = '/gerenciamento';
      url = `${url}?criterio=${crit.toUpperCase() || '0'}`;
      url = `${url}&pagina=${pag}`;
      url = `${url}&qtdePagina=${qtde}`;
      url = `${url}&campoOrdenacao=${campo}`;
      url = `${url}&sentidoOrdenacao=${sentido}`;
      history.push(url);
    } catch (e) {
      alertar('error', e.msg || e);
    } finally {
      setCarregando(false);
    }
  };

  const onSubmit = async values => {
    const { paramCriterio } = values;
    // Validar quaisquer campso necessários se houver

    // Comandar a navagação paginada
    navegar(paramCriterio, 1, qtdePagina, campoOrdenacao, sentidoOrdenacao);
  };

  const ordenar = (campo, sentido) => {
    navegar(criterio, pagina, qtdePagina, campo, sentido);
  };

  const paginar = pag => {
    navegar(criterio, pag, qtdePagina, campoOrdenacao, sentidoOrdenacao);
  };

  const classificar = async (id, posicao) => {
    setCarregando(true);
    try {
      const atualizacao = await Funci.atualizarPosicao(id, posicao);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.listarSocios(pagina, qtdePagina, criterio || '0', campoOrdenacao || '0', sentidoOrdenacao || '0');
        if (consulta.erro) {
          setSocios([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setSocios([]);
            alertar('warning', 'Nenhum sócio encontrado.');
          }
          if (consulta.length > 0) {
            const detalhesMap = consulta.map(async c => await Funci.consultarSocioPorId(c.id));
            const detalhes = await Promise.all(detalhesMap);
            if (detalhes.length > 0) {
              const lista = consulta.map(c => {
                const newItem = { ...c };
                const detalhe = detalhes.filter(d => d.id === c.id);
                newItem.meiosComunicacao = detalhe.length > 0 ? detalhe[0].meiosComunicacao || [] : [];
                return newItem;
              });
              setSocios(lista);
              console.log('lista', lista);
            } else {
              setSocios(consulta);
            }
          }
        }
      } catch (e) {
        setSocios([]);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao, atualizar]);

  return (
    <>
      <Cabecalho />
      <Row className="w-100 flex-grow-1">
        <Menu location={location} />
        <Col as="div" className="rolagem">
          <Container fluid>
            <Carregando show={carregando} />
            <br />
            <Tabs defaultActiveKey="socios" id="uncontrolled-tab-example">
              <Tab mountOnEnter unmountOnExit={false} variant="pills" eventKey="socios" title="Sócios Votantes">
                <Row className="px-2">
                  {socios.length > 0 ? (
                    <>
                      <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-end align-items-end">
                        <Row className="justify-content-end">
                          <Card.Text className="pb-0 ml-1">{`Registros encontrados: ${socios[0].records}`}</Card.Text>
                        </Row>
                      </Col>
                      <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-end align-items-end">
                        <Row className="justify-content-end">
                          <ReactToPrint
                            pageStyle="@page { size: auto;  margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
                            trigger={() => <Button>Imprimir</Button>}
                            content={() => componentRef.current}
                          />
                        </Row>
                      </Col>
                    </>
                  ) : null}
                  <Col className="justify-content-end">
                    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
                      <Form.Row className="justify-content-end">
                        <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                          <Form.Control
                            name="paramCriterio"
                            type="text"
                            maxLength={200}
                            placeholder="Digite um título, nome ou categoria"
                            ref={register({
                              maxLength: { value: 200, message: 'A pesquisa deve conter no máximo 200 caracteres.' },
                            })}
                          />
                          <Col as={Card.Text} className="text-danger">
                            {errors.tipo && errors.tipo.message}
                          </Col>
                        </Form.Group>
                        <Col xs="auto">
                          <Button type="submit">Buscar</Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
                <br />
                {/* {socios.length > 0
                  ? socios.map(s => (
                      <Card id="lista-socios">
                        <Card.Header>
                          <Card.Title>{s.nomeCompleto}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col>
                              <img src={s.foto ? s.foto : semFoto} height={150} alt="foto sócio" />
                            </Col>
                            <Col>
                              <Card.Text>Título: {`${s.titulo}`}</Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))
                  : null} */}
                <Table striped bordered hover responsive size="sm" style={{ borderColor: 'black' }} ref={componentRef}>
                  <thead>
                    <tr>
                      <th className="text-center">Foto</th>
                      <ThWithOrder
                        className="text-center"
                        ordenar={ordenar}
                        campo="titulo"
                        campoAtual={campoOrdenacao}
                        ordemAtual={sentidoOrdenacao}
                      >
                        Titulo
                      </ThWithOrder>
                      <ThWithOrder className="" ordenar={ordenar} campo="nome" campoAtual={campoOrdenacao} ordemAtual={sentidoOrdenacao}>
                        Nome Reduzido
                      </ThWithOrder>
                      <ThWithOrder className="" ordenar={ordenar} campo="nomeCompleto" campoAtual={campoOrdenacao} ordemAtual={sentidoOrdenacao}>
                        Nome Completo
                      </ThWithOrder>
                      <ThWithOrder
                        className="text-center"
                        ordenar={ordenar}
                        campo="categoria"
                        campoAtual={campoOrdenacao}
                        ordemAtual={sentidoOrdenacao}
                      >
                        Categoria
                      </ThWithOrder>
                      <th className="text-center">Comunicação</th>
                      <ThWithOrder
                        className="text-center"
                        ordenar={ordenar}
                        campo="posicao"
                        campoAtual={campoOrdenacao}
                        ordemAtual={sentidoOrdenacao}
                      >
                        Posição
                      </ThWithOrder>
                      <th className="text-center">Detalhes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {socios.length > 0
                      ? socios.map(s => (
                          <tr className="" key={`${s.id}`}>
                            <td className="align-middle text-center">
                              <img src={s.foto ? s.foto : semFoto} height={150} alt="foto sócio" />
                            </td>
                            <td className="align-middle text-center">{`${s.titulo}`}</td>
                            <td className="align-middle">{s.nome}</td>
                            <td className="align-middle">{s.nomeCompleto}</td>
                            <td className="align-middle text-center">{s.categoria}</td>
                            <td className="align-middle text-center">
                              {`Email: ${parseMeioComunica(s.meiosComunicacao || [], 'EMAIL')}`}
                              <br />
                              {`Telefones: ${parseMeioComunica(s.meiosComunicacao || [], 'TELEFONE')}`}
                            </td>
                            <td className="align-middle text-center">
                              <Posicao id={s.id} posicao={s.posicao} classificar={classificar} />
                            </td>
                            <td className="align-middle text-center">
                              <LinkContainer to={`/socio/${s.id}`}>
                                <Badge variant="secondary">Clique aqui</Badge>
                              </LinkContainer>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
                <Row sm="auto" className="px-2 justify-content-center">
                  <Paginacao records={socios.length > 0 ? socios[0].records : 0} paginaAtual={pagina} qtdePorPagina={qtdePagina} paginar={paginar} />
                </Row>
              </Tab>
            </Tabs>
          </Container>
        </Col>
      </Row>
    </>
  );
};
