import React from 'react';

const Posicao = props => {
  const { id = 0, labels = false, posicao = '', classificar = () => {} } = props;
  const colors = { F: 'text-success', N: 'text-warning', C: 'text-danger' };
  const getColor = tipo => (posicao === tipo ? colors[tipo] : 'text-secondary');

  return (
    <div className="px-4 d-flex justify-content-between text-center">
      <div className={labels ? 'mx-4' : ''} onClick={() => classificar(id, posicao !== 'F' ? 'F' : 'X')}>
        <span className="fa-stack fa-lg">
          <i className={`fa fa-stack-2x fa-square-o ${getColor('F')}`} />
          <i className={`fa fa-stack-lg fa-thumbs-o-up ${getColor('F')}`} />
        </span>
        {labels && <span className={`${getColor('F')}`}>Favorável</span>}
      </div>
      <div className={labels ? 'mx-4' : ''} onClick={() => classificar(id, posicao !== 'N' ? 'N' : 'X')}>
        <span className="fa-stack fa-lg">
          <i className={`fa fa-stack-2x fa-square-o ${getColor('N')}`} />
          <i className={`fa fa-stack-lg fa-meh-o ${getColor('N')}`} />
        </span>
        {labels && <span className={`${getColor('N')}`}>Neutro</span>}
      </div>
      <div className={labels ? 'mx-4' : ''} onClick={() => classificar(id, posicao !== 'C' ? 'C' : 'X')}>
        <span className="fa-stack fa-lg">
          <i className={`fa fa-stack-2x fa-square-o ${getColor('C')}`} />
          <i className={`fa fa-stack-lg fa-thumbs-o-down ${getColor('C')}`} />
        </span>
        {labels && <span className={`${getColor('C')}`}>Contrário</span>}
      </div>
    </div>
  );
};

export default Posicao;
