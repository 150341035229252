import React from 'react';
import { useLocation } from 'react-router-dom';
import { Voltar } from '.';

export default () => {
  let location = useLocation();

  return (
    <div>
      <Voltar />
      <h3>
        Caminho não encontrado <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
