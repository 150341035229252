import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { Accordion, Container, Card, Row, Col } from 'react-bootstrap';

import { Voltar, Carregando, Cabecalho, Menu, Posicao, AccordionLineToggle } from '../../components';
import { Funci } from '../../services';
import { alertar, parseMeioComunica } from '../../utils';
import semFoto from '../../assets/imgs/semfoto.jpg';

export default () => {
  const { id } = useParams();
  const location = useLocation();
  const [atualizar, setAtualizar] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [socio, setSocio] = useState(null);
  const posicaoLabel = {
    F: { color: 'text-success', label: 'Favorável' },
    N: { color: 'text-warning', label: 'Neutro' },
    C: { color: 'text-danger', label: 'Contrário' },
    X: { color: 'text-secondary', label: 'Não definido' },
  };

  const classificar = async (idSocio, posicao) => {
    setCarregando(true);
    try {
      const atualizacao = await Funci.atualizarPosicao(idSocio, posicao);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.consultarSocioPorId(id);
        if (consulta.erro) alertar('danger', consulta.erro);
        if (consulta.id) {
          setSocio(consulta);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, atualizar]);

  return (
    <>
      <Cabecalho />
      <Row className="w-100 flex-grow-1">
        <Menu location={location} />
        <Col as="div" className="rolagem">
          <Container fluid>
            <Carregando show={carregando} />
            <br />
            <Row className="justify-content-between">
              <Voltar />
            </Row>
            {socio ? (
              <Card>
                <Card.Header as="h6">Detalhe Sócio</Card.Header>
                <Card.Body>
                  <Card.Title>
                    {`${socio.nomeCompleto}`}
                    {socio.nome ? ` (${socio.nome})` : ''}
                  </Card.Title>
                  <Row>
                    <Col>
                      <Card.Text>
                        {`Título: ${socio.titulo}`}
                        <br />
                        {`Categoria: ${socio.categoria}`}
                        <br />
                        {`Posição: `}
                        <span className={posicaoLabel[socio.posicao || 'X'].color || ''}>
                          {posicaoLabel[socio.posicao || 'X'].label || 'Não definida'}
                        </span>
                      </Card.Text>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <img src={socio.foto ? socio.foto : semFoto} height={150} alt="foto sócio" />
                    </Col>
                  </Row>
                  <br />
                  <Card.Title>Contatos</Card.Title>
                  <Row>
                    <Col>
                      <Card.Text>{`Email: ${parseMeioComunica(socio.meiosComunicacao || [], 'EMAIL')}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Telefones: ${parseMeioComunica(socio.meiosComunicacao || [], 'TELEFONE')}`}</Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Posicao labels id={socio.id} posicao={socio.posicao} classificar={classificar} />
                    </Col>
                  </Row>
                  <Accordion defaultActiveKey="0" className="mt-4 mb-2">
                    <Card className="border-0">
                      <AccordionLineToggle eventKey="0">Histórico</AccordionLineToggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Row>
                            <Col>Data/Hora</Col>
                            <Col>Responsável</Col>
                            <Col>Intenção anterior</Col>
                            <Col>Nova Intenção</Col>
                          </Row>
                          {socio.historico && socio.historico.length > 0 ? (
                            <>
                              {socio.historico.map((h, index) => (
                                <Row className="" key={`${index}-${h.timestamp}`}>
                                  <Col>{`${moment(h.timestamp).format('DD/MM/YYYY HH:mm:ss')}`}</Col>
                                  <Col>{`${h.email}`}</Col>
                                  <Col className={`${posicaoLabel[h.posAnt || 'X'].color || 'text-secondary'}`}>{`${
                                    posicaoLabel[h.posAnt || 'X'].label || 'Não definido'
                                  }`}</Col>
                                  <Col className={`${posicaoLabel[h.posNova || 'X'].color || 'text-secondary'}`}>{`${
                                    posicaoLabel[h.posNova || 'X'].label || 'Não definido'
                                  }`}</Col>
                                </Row>
                              ))}
                            </>
                          ) : (
                            <Col>
                              <Card.Text>{`Nenhum histórico registrado`}</Card.Text>
                            </Col>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Card.Body>
              </Card>
            ) : (
              <Card>
                <Card.Header as="h6">Detalhe sócio</Card.Header>
                <Card.Body>
                  <Card.Title>Sócio não encontrado.</Card.Title>
                </Card.Body>
              </Card>
            )}
          </Container>
        </Col>
      </Row>
    </>
  );
};
