import React from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container, Card } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { Cabecalho, Menu } from '../../components';

export default () => {
  const location = useLocation();
  return (
    <>
      <Cabecalho />
      <Row className="w-100 flex-grow-1">
        <Menu location={location} />
        <Col as="div" className="rolagem">
          <Container fluid className="h-100">
            <br />
            <Card className="text-center">
              <Card.Body>
                <Card.Title>Gerenciador de Intenções</Card.Title>
                <Card.Text>Selecione um item do menu.</Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </>
  );
};
