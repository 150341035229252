import React from 'react';
import { Alert, Col } from 'react-bootstrap';

const Alerta = props => {
  const { tipo = 'warning', mensagem = '', show = false, fecharAlerta = () => {} } = props;

  if (show) {
    return (
      <Col>
        <Alert transition={null} variant={tipo} onClose={() => fecharAlerta()} dismissible>
          {`${mensagem}`}
        </Alert>
      </Col>
    );
  }
  return null;
};

export default Alerta;
