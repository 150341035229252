import React, { useState } from 'react';
import { Container, Card, Form, Button, Row } from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { Carregando, Alerta } from '../../components';
import { Auth } from '../../services';
import { validarEmail } from '../../utils';

export default withRouter(props => {
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [redirect, setRedirect] = useState(false);
  const [validatedLogin, setValidatedLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) login();
    setValidatedLogin(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const login = async () => {
    fecharAlerta();
    setCarregando(true);
    try {
      if (!validarEmail(email)) {
        setValidatedLogin(false);
        return alertar('danger', 'Email inválido.');
      }

      // Login
      const loggedIn = await Auth.conectarComEmailESenha(email, senha);
      if (loggedIn === true) {
        setRedirect(true);
      }
    } catch (e) {
      alertar('danger', e.msg || e);
      setValidatedLogin(false);
      setCarregando(false);
    }
  };

  if (redirect) return <Redirect to="/" />;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
      <Container className="w-50">
        <Carregando show={carregando} />
        <br />
        {alerta.show ? (
          <Row className="justify-content-between">
            <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
          </Row>
        ) : null}
        <Card className="text-center" xs="auto">
          <Card.Body>
            <Card.Title>LOGIN</Card.Title>
            <Form noValidate validated={validatedLogin} onSubmit={handleSubmit}>
              <Form.Group xs={4} controlId="email" className="">
                <Form.Control required type="text" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Email inválido'}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group xs={4} controlId="senha" className="">
                <Form.Control required type="password" placeholder="Senha" value={senha} onChange={event => setSenha(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Digite sua senha'}</Form.Control.Feedback>
              </Form.Group>

              <Button variant="success" type="submit">
                Entrar
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
});
