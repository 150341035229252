const ambiente = process.env.REACT_APP_ENV === 'development' ? 'development' : 'production';

const env = {
  SECRET: 'm1n4sS3cr3t',
  SERVIDOR: ambiente,
  // URL_API: ambiente === 'development' ? 'https://api.minasbrasilia.com.br:3300' : 'https://api.minasbrasilia.com.br:3300',
  URL_API: ambiente === 'development' ? 'https://3zsmartapps.dev.br:3800' : 'https://3zsmartapps.dev.br:3800',
  // URL_API: ambiente === 'development' ? 'https://api.minasbrasilia.com.br:3300' : 'http://192.168.0.8:3300',
  // URL_API: ambiente === 'development' ? 'http://localhost:3000' : 'http://192.168.0.9:3000',
  // URL_API: ambiente === 'development' ? 'http://10.0.0.130:3000' : 'http://10.0.0.130:3000',
};

export default env;
